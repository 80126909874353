<template>
    <div>
        <v-icon :class="`mr-2 ${color}--text`" v-if="$_.has(field, 'icon')">{{
            field.icon
        }}</v-icon>

        <a
            v-if="$_.has(field, 'href')"
            :href="$_.get(field, 'href', '') + $_.get(contact, field.name)"
            >{{ $_.get(contact, field.name, "") }}
        </a>

        <span
            v-if="
                $_.get(field, 'type', 'text') === 'text' &&
                !$_.has(field, 'href')
            "
            >{{
                $_.has(field, "transform")
                    ? field.transform($_.get(contact, field.name, ""))
                    : $_.get(contact, field.name, "")
            }}</span
        >

        <v-rating
            v-if="$_.get(field, 'type', 'text') === 'rating'"
            readonly
            background-color="red lighten-2"
            dense
            :color="`${color} darken-1`"
            half-increments
            length="5"
            hover
            size="20"
            :value="$_.get(contact, field.name, '')"
        ></v-rating>
    </div>
</template>
<script>
export default {
    name: "contact-view-item",
    components: {},
    props: {
        contact: {
            type: Object,
            required: true,
        },
        field: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>