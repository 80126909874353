var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$_.has(_vm.field, "icon")
        ? _c("v-icon", { class: "mr-2 " + _vm.color + "--text" }, [
            _vm._v(_vm._s(_vm.field.icon))
          ])
        : _vm._e(),
      _vm.$_.has(_vm.field, "href")
        ? _c(
            "a",
            {
              attrs: {
                href:
                  _vm.$_.get(_vm.field, "href", "") +
                  _vm.$_.get(_vm.contact, _vm.field.name)
              }
            },
            [_vm._v(_vm._s(_vm.$_.get(_vm.contact, _vm.field.name, "")) + " ")]
          )
        : _vm._e(),
      _vm.$_.get(_vm.field, "type", "text") === "text" &&
      !_vm.$_.has(_vm.field, "href")
        ? _c("span", [
            _vm._v(
              _vm._s(
                _vm.$_.has(_vm.field, "transform")
                  ? _vm.field.transform(
                      _vm.$_.get(_vm.contact, _vm.field.name, "")
                    )
                  : _vm.$_.get(_vm.contact, _vm.field.name, "")
              )
            )
          ])
        : _vm._e(),
      _vm.$_.get(_vm.field, "type", "text") === "rating"
        ? _c("v-rating", {
            attrs: {
              readonly: "",
              "background-color": "red lighten-2",
              dense: "",
              color: _vm.color + " darken-1",
              "half-increments": "",
              length: "5",
              hover: "",
              size: "20",
              value: _vm.$_.get(_vm.contact, _vm.field.name, "")
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }